import { mergeClassNames } from "../utils/tailwind.js";

export default function Button({ disabled = false, children, className, ...props }) {
    return (
        <button 
            disabled={disabled}
            className={
                mergeClassNames(
                    `bg-indigo-${disabled ? 300: 700} p-3 text-white rounded-lg`,
                    className,
                )
            }
            {...props}
        >
            {children}
        </button>
    );
}