import { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import check from 'check-types';
import AuthWidget from './auth/AuthWidget.js';
import Button from './ui/Button.js';
import Input from './ui/Input.js';
import useAuthState from './auth/useAuthState.js';
import HipHop from './initializers/hiphop.js';
import auth from './initializers/auth.js';

const SOURCES = [
  'google',
  'lexica',
  'lexica-aperture',
];

const GENERATORS = [
  'dall-e',
  'stable-diffusion',
  'sdxl',
  'clipdrop',
];

const PRESETS = [
  'ai/night-portrait',
  'ai/portrait',
  'ai/interior-design',
  'ai/3d-icon',
  'ai/flat-illustration',
  'ai/3d-clay-icon',
  'ai/poly-clay-animal',
  'ai/clear-style',
  'ai/children-book',
  'ai/vector-art',
  'ai/comics',
  'ai/outline-icon',
  'ai/undraw'
].sort();

const TRANSFORMS = [
  'remove-bg',
  'vectorize',
  'upscale',
  'zoom-out',
  'portrait-depth',
  'portrait-surface',
  'resize',
  'thumbnail',
  'jpg',
  'png',
  "webp"
]

function HStack({ style, children }) {
  return <div
    className='flex-wrap'
    style={{
      display: 'flex',
      flexDirection: 'row',
      ...style
    }}
  >{children}</div>
}

function VStack({ style, children }) {
  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...style
    }}
  >{children}</div>
}

function Tag({ children, colorScheme="slate", onClick=()=>{} }) {
  return <div
    className={`px-2 py-1 rounded-lg bg-${colorScheme}-500 text-slate-100 text-center cursor-pointer mr-1 mb-1 text-sm`}
    onClick={onClick}
  >{children}</div>
}

function Title({children}) {
  return <h1 className='text-md font-bold mt-4'>{children}</h1>
}

function Subtitle({children}) {
  return <h2 className='text-sm font-medium mt-2 mb-1'>{children}</h2>
}

async function fetchPicture(queryText) {
  /*
  const response = await superagent
    .get('./api/query.js')
    .query({ 
      q: queryText,
      apiKey: API_KEY
    })
    .set('Accept', 'application/json')

    console.log(response.body);

    if (response.body && response.body.results && response.body.results[0]) {
      return response.body.results[0];
    }
    return null;
  */

  const image = await HipHop.withAuth(await auth.getToken()).generateImage(queryText);
  return image.raw;
}

function App() {
  const { loggedIn } = useAuthState();
  const { q = null } = queryString.parse(window.location.hash);

  const [command, setCommand] = useState(q || '');
  const [pic, setPic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFocused, setImageFocused] = useState(false);

  const loadPicture = (pictureQuery) => {
    setLoading(true);
    fetchPicture(pictureQuery)
      .then(pic => {
        setPic(pic);
        setImageFocused(true);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }
  
  // Pre-load query from URL if any
  useEffect(() => {
    if (check.nonEmptyString(q) && !loading && !pic) {
      setTimeout(() => loadPicture(q), 500);
    }
  }, []);

  // Update query string when updating command
  const setCommandAndQuery = (txt) => {
    setCommand(txt);
    window.location.hash = queryString.stringify({ q: txt });
  }

  const addSource = (source) => {
    let newCmd = command.trim();

    // Remove any existing source
    [...SOURCES, ...GENERATORS, ...PRESETS].forEach(s => {
      if (command.toLowerCase().startsWith(s.toLowerCase())) {
        newCmd = newCmd.substring(s.length).trim();
        if (newCmd.startsWith(':')) {
          newCmd = newCmd.substring(1).trim();
        }
      }
    });

    // Add new source
    newCmd = source + ': ' + newCmd;
    
    setCommandAndQuery(newCmd);
  } 

  const addTransform = (transform) => {
    let newCmd = command.trim();

    if (!check.nonEmptyString(newCmd)) {
      return;
    }

    // Add new source
    newCmd += ' | ' + transform;
    setCommandAndQuery(newCmd);
  } 

  const nextVariant = (increment = 1) => {
    let newCmd = command.trim();

    if (!check.nonEmptyString(newCmd)) {
      return;
    }

    const parts = newCmd.split('|');

    let variant = 0;
    let newSource = parts[0].trim();
    if (newSource.includes('%')) {
      
      const currentVariants = newSource.match(/%[0-9]+/g);
      if (check.nonEmptyArray(currentVariants)) {
        variant = parseInt(currentVariants[0].substring(1), 10);
      }
    }
    newSource = newSource.replaceAll(/%[0-9]+/g, '').trim() + ' %' + (Math.max(0, variant + increment)) + ' ';

    // Re-assemble
    parts[0] = newSource.replace(' %0 ', ' ');
    newCmd = parts.join('|').trim();
    setCommandAndQuery(newCmd);
  } 

  let picture = pic ? (<div 
    className='transition ease-in-out delay-150 cursor-pointer'
    onClick={() => setImageFocused(true)}
    style={{
      flex: '1',
      backgroundImage: `url(${pic})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }}
  />): null;

  if (loading) {
    picture =  <div 
      className='transition ease-in-out delay-150 cursor-pointer'
      onClick={() => setImageFocused(true)}
      style={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
      }}
    ><div>Loading...</div></div>
  }

  return (
    <VStack style={{
      width:'90vw',
      height:'90vh',
      backgroundColor: '#FEFEFE',
      marginLeft: '5vw',
      marginRight: '5vw',
      marginTop: '5vh',
      marginBottom: '5vh',
    }} >
      <h1 className='text-2xl mb-2'>Let's HipHop!</h1>
      <AuthWidget />
      
      {loggedIn && (
        <Fragment>
          <HStack style={{width: '90vw'}}>
            <Input
              className={'rounded-r-none'}
              type="text" 
              onClick={() => setImageFocused(false)}
              placeholder='Command'
              onChange={e => setCommandAndQuery(e.target.value)}
              value={command}
              disabled={loading}
            />
            <Button 
              className='rounded-l-none'
              disabled={loading}
              onClick={() => loadPicture(command)}
            >
              {loading ? 'Loading...' : 'Fetch picture'}
            </Button>
          </HStack>
          
            {imageFocused ? null : (
              <div className='transition ease-in-out delay-150'>
                <Title>Image source</Title>
                <Subtitle>Search</Subtitle>
                <HStack>
                  {SOURCES.map(source => <Tag key={source} colorScheme="blue" onClick={() => addSource(source)}>{source}</Tag>)}
                </HStack>
                <Subtitle>Models</Subtitle>
                <HStack>
                  {GENERATORS.map(source => <Tag key={source} colorScheme="green" onClick={() => addSource(source)}>{source}</Tag>)}
                </HStack>
                <Subtitle>Presets</Subtitle>
                <HStack>
                  {PRESETS.map(source => <Tag key={source} colorScheme="pink" onClick={() => addSource(source)}>{source}</Tag>)}
                </HStack>

                <Title>Transforms</Title>
                <HStack>
                  {TRANSFORMS.map(source => <Tag key={source} colorScheme="orange" onClick={() => addTransform(source)}>{source}</Tag>)}
                </HStack>

                <Title>Variants</Title>
                <HStack>
                  <Tag onClick={() => nextVariant(-1)}>Prev</Tag>
                  <Tag onClick={() => nextVariant(1)}>Next</Tag>
                </HStack>
                
              </div>
            )}
          
          <div style={{flex: '1', padding: '2rem', display:'flex' }} >
            {picture}
          </div>
        </Fragment>
      )}
      
      
    </VStack>
  );
}

export default App;
