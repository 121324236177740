import check from 'check-types';
import DescopeSdk from '@descope/web-js-sdk';
import EventEmitter from 'events';
import jsonStable from 'json-stable-stringify';
import { signInWithCustomToken,  } from 'firebase/auth';
import { auth, onAuthStateChange, signOut } from '../initializers/firebase.js';
import { Mutex } from 'async-mutex';

const projectId = 'P2Ok3CCrAGsnAih1ojFRP9B2PmOz';

const MAX_RETRIES = 5;

const DEFAULT_SESSION_STATE = {
    customToken: null,

    error: null,
    pausedForError: false,
    nRetries: 0,
};

const DEFAULT_AUTH_STATE = {
    uid: null,
    email: null,
    uniqueMachineOrUserId: null,
    refreshToken: null,

    loggedIn: false,
    registered: false,
    emailVerificationRequired: false,

    ...DEFAULT_SESSION_STATE,
};

const PRIVACY_MASK = {
    customToken: 'xxxx-xxxx-xxxx',
    refreshToken : 'xxxx-xxxx-xxxx'
};

class Auth extends EventEmitter {
    constructor(hipHopApiClient, storageEngine) {
        super();
        this.hipHop = hipHopApiClient;
        this.descopeSdk = null;
        this.currentProcess = null;
        this.storageEngine = storageEngine || {
            get: async (key) => localStorage[key],
            set: async (key, value) => localStorage[key] = value,
        };

        this.authState = null;
        this.authStateListener = null;
        this.mutex = new Mutex();
        this._initialize();
    }

    // Returns the tokens which should be used for authentication
    async getToken() {
        return this.authState.loggedIn ? (await auth.currentUser.getIdToken(true)) : null;
    }

    // Returns public auth state 
    getAuthState() {
        let state = this.authState || {};
        return {
            ...DEFAULT_AUTH_STATE,
            ...state,
            ...(Object.keys(PRIVACY_MASK).reduce((acc, val) => ({ ...acc, [val]: state[val] ? PRIVACY_MASK[val] : null}), {})),
        };
    }

    // To be called every time the user's authentification data changes
    // This call triggers a background auth process
    setUseridentificationData(email, uniqueMachineOrUserId = null) {
        // If email has changed, reset state completely
        if (check.nonEmptyString(email) && this.authState && (!check.nonEmptyString(this.authState.email) || this.authState.email.toLowerCase().trim() !== email.toLowerCase().trim())) {
            this.logout()
                .then(() => this._setAuthState({ email, uniqueMachineOrUserId }))
                .catch(console.error);
            return;
        }

        // Else, only apply new values
        this._setAuthState({ email, uniqueMachineOrUserId });
    }

    // Starts the process of verifying the user e-mail to upgrade her account.
    // The auth engine will generate an Integer which should be displayed to the user.
    // The user will then receive an email with 3 numbers and she must click on the right one to validate the process.
    // If the process is successful, we can attempt exchanging the e-mail verification token with a custom token from the server
    async launchEmailVerificationProcess(email) {
        const resp = await this.descopeSdk.enchantedLink.signUpOrIn(email, 'https://hiphop-smarco-seafront.vercel.app/api/auth/verify-link.js');
        if (!resp.ok) {
            console.log("Failed to _initialize signUpOrIn flow")
            console.log("Status Code: " + resp.code)
            console.log("Error Code: " + resp.error.errorCode)
            console.log("Error Description: " + resp.error.errorDescription)
            console.log("Error Message: " + resp.error.message)
            throw new Error(resp.error.message);
        }

        const { linkId, pendingRef } = resp.data;
        this.currentProcess = { linkId, pendingRef };
        setTimeout(async () => {
            let res = null;
            try {
                res = await this.descopeSdk.enchantedLink.waitForSession(pendingRef);
                if (this.currentProcess && this.currentProcess.linkId === linkId) {
                    this._onEmailVerified(res);
                }
            } catch(err) {
                console.error(err);
            }
        }, 50);
        
       return linkId;
    }

    // To be called when the user cancels a pending email verification process
    cancelCurrentEmailVerificationProcess() {
        this.currentProcess = null;
    }

    // User action to retry auth manually when crashed
    retryAuth() {
        this._setAuthState({ error: null, nRetries: 0 });
    }

    // Logout
    async logout() {
        this._setAuthState(DEFAULT_AUTH_STATE);

        try {
            await signOut();
        } catch(err) {
            console.error(err);
        }
    }

    _initialize() {
        if (!this.authStateListener) {
            this.authStateListener = onAuthStateChange((user) => {
                console.log('⭐️ Firebase auth state changed: ', user)
                if (user && user.uid) {
                    // Logout from Firebase if uid doesn't match what we expect
                    if (this.authState.uid && this.authState.uid !== user.uid) {
                        console.log('Signing out from Firebase');
                        signOut().catch(console.error);
                        return;
                    }

                    // Else, retrieve session
                    this._setAuthState({
                        uid: user.uid,
                        loggedIn: true,
                        registered: user.uid.startsWith('user_'),
                        ...(user.email ? {email: user.email} : {}),
                    });
                } else {
                    this._setAuthState({
                        uid: null,
                        loggedIn: false,
                        registered: false,
                    });
                }
            })
        }

        // _initialize the state machine async
        this._processAuthState().catch(console.error);
    }

    // Mutexed to avoid parallel requests to server
    async _processAuthState() {
        await this.mutex.runExclusive(async () => {
            console.log('[_processAuthState()] Entering...');

            // 0. Make sure the object is properly _initialized 
            if (!this.descopeSdk) {
                try {
                    this.descopeSdk = DescopeSdk({ projectId });
                }
                catch(err) {
                    console.error(err);
                }
                if (!this.descopeSdk) {
                    return;
                }
            }
            console.log('[_processAuthState()] After 0');

            // 1. Restore auth state from storage
            if (!check.nonEmptyObject(this.authState)) {
                this._setAuthState((await this._restoreState()) || {});
            }
            const { uid, email, refreshToken, customToken, loggedIn, emailVerificationRequired, error, pausedForError } = this.authState;
            console.log('[_processAuthState()] After 1');

            // 2. Make sure the user has an email set 
            if (!check.nonEmptyString(email)) {
                // Do nothing until an email is set
                console.log('[_processAuthState()] Waiting for an email')
                return;
            }
            console.log('[_processAuthState()] After 2');

            // 3. Process only if there's no error
            if (error || pausedForError) {
                console.log('[_processAuthState()] Paused because errored');
                console.log('Current auth state: ', this.authState)
                return;
            }

            // 4. If we are loggedIn, nothing more to do --> mission accomplished
            // Delete the customToken so we don't try to reuse it
            if (loggedIn && check.nonEmptyString(uid)) {
                // this._setAuthState({ customToken: null });
                return;
            }
            console.log('[_processAuthState()] After 3');

            // 5. If the server requested a full login flow, we pause the machine
            //    until the e-mail gets verified (by an action from the user)
            if (emailVerificationRequired) {
                return;
            }
            console.log('[_processAuthState()] After 4');

            // 6. If we have a fresh customToken, try to exchange it with a Firebase token
            //    to initiate the session 
            if (customToken) {
                try {
                    const res = await signInWithCustomToken(auth, customToken);
                    if (res && res.user) {
                        this._setAuthState({ loggedIn: true, error: null, nRetries: 0, customToken: null });
                    }
                    console.log('signInWithCustomToken: ok', res);
                } catch(err) {
                    console.error('signInWithCustomToken: error', err);
                    this._setAuthState({ customToken: null });
                    this._retryLater(err.message);
                }
                
                return;
            }
            console.log('[_processAuthState()] After 5');

            // 7. If we are not fully logged in but have a refreshToken, restore session 
            if (refreshToken) {
                try {
                    await this._restoreSession();
                } catch(err) {
                    console.error(err);
                    this._retryLater(err.message);
                }
                return;
            }
            console.log('[_processAuthState()] After 6');

            // 8. If we don't have a restore token, we can open a light session
            // unless the server already requested a full login flow 
            try {
                await this._initLightSession();
            } catch(err) {
                console.error(err);
                this._retryLater(err.message);
            }
            
            console.log('[_processAuthState()] After 7');
            return;
        });
    }

    _setAuthState(update) {
        console.log('Setting auth state: ', update);

        // Create the updated state
        const prevState = { ...this.authState };
        this.authState = { ...this.authState, ...(check.nonEmptyObject(update) ? update : {})};

        // Emit event
        this.emit('auth-state-changed', this.getAuthState());

        // If state has not changed, abort
        if (jsonStable(prevState) === jsonStable(this.authState)) {
            return;
        }

        // Else, store new state and process it
        this._saveState(this.authState)
            .then(() => {
                // Log new state 
                console.log('#### 🔐 AUTH STATE CHANGED ####\n' + JSON.stringify(
                    this.authState, null, 4
                    /*
                    Object.assign({}, this.authState, {
                        customToken: this.authState.customToken ? 'XXXXX' : null,
                        refreshToken: this.authState.refreshToken ? 'XXXXX' : null,
                    }), null, 4)
                    */)) 
                
                    // Process state
                return this._processAuthState();
            })
            .catch(console.error);
    }

    async _restoreState() {
        let state = DEFAULT_AUTH_STATE;
        try {
            state = JSON.parse(await this.storageEngine.get('auth'));
        } catch(err) {
            // Do nothing
            console.error(err);
        }

        return state;
    }

    async _saveState() {
        try {
            await this.storageEngine.set('auth', JSON.stringify({
                ...DEFAULT_AUTH_STATE,
                ...(this.authState || {}),
                ...DEFAULT_SESSION_STATE, // Overwrite session state to make sure it is never stored
            }))
        } catch(err) {
            // Do nothing
            console.error(err);
        }
    }

    // Uses long-lived refreshToken to restore the Firebase session
    async _restoreSession() {
        const { refreshToken } = this.authState || {};
        if (!refreshToken) {
            console.error('⚠️ [Auth] Attempting to restore session without refresh token')
            return;
        }

        const response = await this.hipHop._post('/auth/init.js', {
            refreshToken,
        });

        this._parseResponseToInitSession(response);
    }

    // Handle retrying on error
    _retryLater(error) {
        const { nRetries } = this.authState;

        this._setAuthState({ pausedForError: true });
        if (nRetries > MAX_RETRIES) {
            this._setAuthState({ error: check.nonEmptyString(error) ? error : (error?.message || 'Unknown error'), pausedForError: false });
            return;
        }

        setTimeout(() => {
            this._setAuthState({ error: null, nRetries: (nRetries || 0) + 1, pausedForError: false });
        }, (2^nRetries) * 1000);    
    }

    // Initializes a light session using the user email wihtout verifying it
    async _initLightSession() {
        const { email, uniqueMachineOrUserId } = this.authState || {};

        // Make sure at least an e-mail is provided in the authState
        if (!check.nonEmptyString(email)) {
            throw new Error('Cannot perform light sign in without at least an e-mail');
        }

        // Attempt to get 'anonymous' token from server 
        const response = await this.hipHop._post('/auth/init.js', {
            email,
            uniqueId: check.nonEmptyString(uniqueMachineOrUserId) ? uniqueMachineOrUserId : null,
        });

        this._parseResponseToInitSession(response);
    }

    _parseResponseToInitSession(response) {
        // Parse response
        const { email, success = false, uid, token, refreshToken, requiresLogin } = response || {};

        // If the server asked us to perform a normal sign in, mark state as such
        if (!success || requiresLogin) {
            this._setAuthState({ 
                emailVerificationRequired: true, 
                loggedIn: false, 
                registered: false, 
                customToken: null,
                refreshToken: null,
                uid, 
                email
            });
            return;
        }

        // Else, save the customToken and refreshToken
        // The next _processAuthState() call will attempt to use the customToken to start the session
        this._setAuthState({ 
            emailVerificationRequired: false, 
            loggedIn: false, 
            registered: false, 
            customToken: token, 
            refreshToken,
            uid, 
            email
        });
    }


    

    // Triggered when the user clicks the auth link sent to her by e-mail by Descope
    async _onEmailVerified(res) {
        // Exchange the Descope token (which is used just to verify the email) with a Firebase token
        console.log('🔐 Fetching Firebase auth token for registered user...');
        const { data: { sessionJwt } } = res;
        const response = await this.hipHop._post('/auth/token.js', {
            emailVerificationToken: sessionJwt,
        });
        console.log('🔐 Received response from server: ', response);

        // Sign out from Firebase as uid may have changed 
        try {
            await signOut();
        } catch(err) {
            console.error(err);
        }

        // Indicate that we are registered and overwrite current user info just in case 
        const { token, refreshToken, email, uid } = response;
        this._setAuthState({ 
            uid,
            email,
            loggedIn: false,
            registered: true, 
            emailVerificationRequired: false, 
            customToken: token,
            refreshToken 
        });
    }
}

export default Auth;