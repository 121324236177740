import useAuthState from "./useAuthState.js";
import { useState } from "react";
import isEmail from "is-email";
import Input from "../ui/Input.js";
import Button from "../ui/Button.js";
import HStack from "../ui/HStack.js";
import auth from "../initializers/auth.js";

function EnterEmailScreen({ onSubmit }) {
    const [email, setEmail] = useState('');
    const canSubmit = isEmail(email);

    return (
        <div className="flex justify-between w-full" >
            <HStack className="items-center gap-2">
                Enter your email: 
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </HStack>
            <Button disabled={!canSubmit} onClick={() => onSubmit(email)} >Submit</Button>
        </div>
    );
}

function EmailVerificationProcessScreen({ startVerification, cancelVerification, changeEmail }) {
    const [linkId, setLinkId] = useState(null);
    
    if (!linkId) {
        return (
            <div className="flex justify-between w-full items-center" >
                You need to authenticate your email to continue. 
                <HStack className='gap-2'>
                    <Button onClick={() => startVerification().then(setLinkId).catch(console.error)} >Start</Button>
                    <Button onClick={() => changeEmail()} >Change email</Button>
                </HStack>
            </div>
        );
    }
    
    return (
        <div className="flex justify-between w-full items-center" >
            <HStack className='gap-1' >
                {'Click the following number in your email: '}
                <strong>{linkId}</strong>
            </HStack>
            <Button onClick={() => {
                cancelVerification();
                setLinkId(null);
            }} >Cancel</Button>
        </div>
    );
}

export function AuthContainer({ children }) {
    return (
        <HStack className="flex rounded-lg border border-slate-300 p-3 mb-3 w-[90vw] items-center">
            {children}
        </HStack>
    )
}

function AuthWidgetContent() {
    const authState = useAuthState();
    const [ upgradeRequired, setUpgradeRequired ] = useState(false);

    console.log('Re-rendering AuthWidget', authState);
    

    if (!authState.email) {
        return <EnterEmailScreen onSubmit={(email) => auth.setUseridentificationData(email)}  />;
    }

    if (authState.error) {
        return (
            <div className="flex justify-between w-full" >
                <div className="flex-1">
                    Error authenticating:<br />{authState.error}
                </div>            
                <Button onClick={() => auth.retryAuth()} >Retry</Button>
            </div>
        )
    }

    if (authState.emailVerificationRequired || upgradeRequired) {
        return <EmailVerificationProcessScreen 
            startVerification={() => auth.launchEmailVerificationProcess(authState.email)} 
            cancelVerification={() => {auth.cancelCurrentEmailVerificationProcess(); setUpgradeRequired(false);}}
            changeEmail={() => {auth.logout(); setUpgradeRequired(false);}}
        />;
    }

    if (!authState.loggedIn) {
        return 'Logging in...'
    }

    if (!authState.registered) {
        return (
            <div className="flex justify-between w-full items-center" >
                {'Logged in as free user: ' + authState.uid}
                <HStack className="gap-1">
                    <Button onClick={() => setUpgradeRequired(true)} >Upgrade</Button>
                    <Button onClick={() => auth.logout()} >Logout</Button>
                </HStack>
            </div>
        );
    }

    return (
        <div className="flex justify-between w-full items-center" >
            {'Logged in as premium user: ' + authState.uid}
            <Button onClick={(e) => { auth.logout(); setUpgradeRequired(false); }} >Logout</Button>
        </div>
    );
}

export default function AuthWidget() {
    return <AuthContainer><AuthWidgetContent /></AuthContainer>
}