import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged as onAuthStateChangeNative, signOut as nativeSignOut } from "firebase/auth";
import firebaseConfig from "../config/firebase-web.js";

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase);
const database = getDatabase(firebase);
const onAuthStateChange = (callback) => onAuthStateChangeNative(auth, callback);
const signOut = () => nativeSignOut(auth);


export { firebase, analytics, auth, database, onAuthStateChange, signOut };