import superagent from 'superagent';

export default class HipHopApiClient {
    constructor({ endpoint = '/api', authToken = null } = {}) {
        this.endpoint = endpoint;
        this.authToken = authToken;
    }

    setAuth(token) {
        this.authToken = token;
        return this;
    }

    withAuth(token) {
        const clone = new HipHopApiClient({ endpoint: this.endpoint, authToken: token });
        return clone;
    }

    async generateImage(query) {
        const response = await this._post('/query.js', { q: query });
        return response ? (response?.results?.[0] || null) : null;
    }

    async _post(path, body) {
        let req = superagent
            .post(this.endpoint + path)
            .type('json')
            .accept('application/json');

        if (this.authToken) {
            req = req.set('Authorization', 'Bearer ' + this.authToken)
        }

        const data = await req          
            .send(body);

        return data?.body || null;
    }
};