import { useEffect, useState } from 'react';
import auth from '../initializers/auth.js';

export default function useAuthState() {
    const [state, setState] = useState(auth?.getAuthState() || {});
    useEffect(() => {
        const listener = (state) => {
            setState(state);
        };
        
        auth.on('auth-state-changed', listener);
        return () => auth.off('auth-state-changed', listener);
    }, []);

    return state || {};
}