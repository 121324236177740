import { mergeClassNames } from "../utils/tailwind.js"

export default function Input({ className, ...props }) {
return (
    <input 
        className={
            mergeClassNames(
                'flex-1 rounded-lg border border-slate-300 focus:border-slate-300 p-3',
                className
            )
        }
        {...props}
    />
    )
}