const firebaseConfig = {
    apiKey: "AIzaSyCTwAtKNn5eFHgmdI_D3r2DxEStckS_uWk",
    authDomain: "creative-robots-hiphop.firebaseapp.com",
    databaseURL: "https://creative-robots-hiphop-default-rtdb.firebaseio.com",
    projectId: "creative-robots-hiphop",
    storageBucket: "creative-robots-hiphop.appspot.com",
    messagingSenderId: "638392608130",
    appId: "1:638392608130:web:e1fe4d8e52adbb02451b9b",
    measurementId: "G-5CNYKKNTZP"
};

export default firebaseConfig;