import { mergeClassNames } from "../utils/tailwind.js";

export default function HStack({ className, style, children }) {
    return (
        <div
        className={mergeClassNames('flex flex-row flex-wrap', className)}
        style={{
            ...(style || {})
        }}
        >{children}</div>
    );
}