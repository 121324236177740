import check from 'check-types';
import { flatten as arrayFlatten } from 'array-flatten';

function classNamesToArray(cn) {
    if (check.nonEmptyString(cn)) {
        return cn.split(' ').map(c => c.trim()).filter(check.nonEmptyString);
    }

    if (check.nonEmptyArray(cn)) {
        return arrayFlatten(cn.map(classNamesToArray));
    }

    return [];
}

export function mergeClassNames(...classNames) {
    return arrayFlatten(classNames.map(classNamesToArray)).join(' ');
}